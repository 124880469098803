import { UninstalledExtensionComponent } from './shared/uninstalled-extension/uninstalled-extension.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { PageNotFoundTemplateComponent } from './shared/page-not-found-template/page-not-found-template.component';

const routes: Routes = [
  //keep the logic of old urls
  { path: 'search', redirectTo: 'app/companies/search' },
  { path: 'crm', redirectTo: 'app/crm' },
  { path: 'plugin', redirectTo: 'app/plugin' },
  { path: 'contactBook', redirectTo: 'app/contactBook' },
  { path: 'companies', redirectTo: 'app/companies' },
  { path: 'upgrade', redirectTo: 'app/upgrade' },
  { path: 'myPlan', redirectTo: 'app/myPlan' },
  { path: 'billingDetails', redirectTo: 'app/billingDetails' },
  { path: 'leadstore', redirectTo: 'app/leadstore' },

  {
    path: 'app',
    loadChildren: () =>
      import('./leadspotApp/leadspotApp.module').then(
        (m) => m.LeadspotAppModule
      ),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  { path: 'uninstall', component: UninstalledExtensionComponent },

  { path: '**', component: PageNotFoundTemplateComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
