<div>
  <div class="logo">
    <img src="/assets/newLogo.png" alt="" />
  </div>

  <!-- <div class="sales"></div> -->
  <div class="bg">
    <div class="text flex-col">
      <div class="flex first">
        <img src="/assets/sad.svg" alt="" />
        <span>404 Page not found</span>
      </div>
      <span class="second">
        Oops, the page you are looking for is not found
      </span>

      <button (click)="homePage()">BACK TO HOME PAGE</button>
    </div>
  </div>
</div>
