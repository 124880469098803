<div>
  <div class="logo">
    <img src="/assets/newLogo.png" alt="" />
  </div>

  <!-- <div class="sales"></div> -->
  <div class="bg">
    <div class="text flex-col">
      <div class="flex first">
        <!-- <img src="/assets/sad.svg" alt="" /> -->
        <span>Why you’re uninstalling?</span>
      </div>
      <div class="options">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios1"
            value="option1"
            checked
          />
          <label class="form-check-label" for="exampleRadios1">
            Don't use Leadspotting anymore
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios2"
            value="option2"
          />
          <label class="form-check-label" for="exampleRadios2">
            It crashes too often
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios1"
            value="option1"
          />
          <label class="form-check-label" for="exampleRadios1">
            Too slow
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios1"
            value="option1"
          />
          <textarea name="" id="" cols="30" rows="5"></textarea>
        </div>
      </div>
      <!-- <span class="second">

      </span> -->
      <div class="back-div">
        <button (click)="homePage()">BACK TO HOME PAGE</button>
      </div>
    </div>
  </div>
</div>
