import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import AOS from 'aos';
// declare ga as a function to access the JS code in TS
declare var ga;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'LeadSpot';
  constructor(public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  ngOnInit(): void {
    AOS.init();
  }
}
