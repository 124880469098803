import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { BackEndService } from './shared/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router : Router,private backEnd:BackEndService){}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // this.backEnd.getUserClaims().subscribe(res=>{
    //   
    //   return true;
    // }
    // ,err=>{
    //   
    //   localStorage.clear()
    //   this.router.navigateByUrl('/')
    //   return false
    // })
    return true
  }

}
