import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found-template',
  templateUrl: './page-not-found-template.component.html',
  styleUrls: ['./page-not-found-template.component.scss']
})
export class PageNotFoundTemplateComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  homePage(){
    this.router.navigate([''])
  }
}
