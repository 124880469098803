import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashService {

  contactEnrichment;
  companySearch;
  linkedIn;
  leadsGeneration;
  companyContactDiscovery
  companySearchStats

  contactHistory

  userPlan

  selectedUser;

  constructor() { }
}
