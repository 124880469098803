import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uninstalled-extension',
  templateUrl: './uninstalled-extension.component.html',
  styleUrls: ['./uninstalled-extension.component.scss'],
})
export class UninstalledExtensionComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  homePage() {
    this.router.navigate(['']);
  }
}
